define("discourse/plugins/swapd-kyc-checker/discourse/connectors/topic-above-footer-buttons/swapd-kyc-banner", ["exports", "@glimmer/component", "@ember/service", "@ember/helper", "discourse-common/helpers/d-icon", "discourse-i18n", "@ember/template", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _helper, _dIcon, _discourseI18n, _template, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SwapdKycBanner extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get showAdminBanner() {
      return this.currentUser && this.currentUser.can_view_tickets && (this.args.outletArgs.model?.seller_must_kyc_confirm || this.args.outletArgs.model?.buyer_must_kyc_confirm);
    }
    get showUserBanner() {
      return this.currentUser && this.currentUser.can_view_tickets && this.args.outletArgs.model?.current_user_must_kyc_confirm;
    }
    get adminBannerText() {
      var str = "";
      if (this.args.outletArgs.model?.seller_must_kyc_confirm) {
        str = _discourseI18n.default.t("swapd_kyc_checker.banner.admin_notice", {
          role: "Seller",
          username: this.args.outletArgs.model?.seller_username
        });
      }
      if (this.args.outletArgs.model?.buyer_must_kyc_confirm) {
        if (str != "") {
          str += "<br>";
        }
        str += _discourseI18n.default.t("swapd_kyc_checker.banner.admin_notice", {
          role: "Buyer",
          username: this.args.outletArgs.model?.buyer_username
        });
      }
      return (0, _template.htmlSafe)(str);
    }
    get userBannerText() {
      return (0, _template.htmlSafe)(_discourseI18n.default.t("swapd_kyc_checker.banner.user_notice"));
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showAdminBanner}}
          <div class="post-notice custom kyc-banner">
            {{icon 'exclamation-triangle'}}
            {{icon 'user-check'}}
            <div>{{ this.adminBannerText }}</div>
            <div class="icon-right">{{icon 'exclamation-triangle'}}</div>
          </div>
        {{/if}}
        {{#if this.showUserBanner}}
          <div class="post-notice custom kyc-banner">
            {{icon 'exclamation-triangle'}}
            {{icon 'user-check'}}
            <div>{{ this.userBannerText }}</div>
            <div class="icon-right">{{icon 'exclamation-triangle'}}</div>
          </div>
        {{/if}}
      
    */
    {
      "id": "rO6NDSTa",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showAdminBanner\"]],[[[1,\"      \"],[10,0],[14,0,\"post-notice custom kyc-banner\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"exclamation-triangle\"],null]],[1,\"\\n        \"],[1,[28,[32,0],[\"user-check\"],null]],[1,\"\\n        \"],[10,0],[12],[1,[30,0,[\"adminBannerText\"]]],[13],[1,\"\\n        \"],[10,0],[14,0,\"icon-right\"],[12],[1,[28,[32,0],[\"exclamation-triangle\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showUserBanner\"]],[[[1,\"      \"],[10,0],[14,0,\"post-notice custom kyc-banner\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"exclamation-triangle\"],null]],[1,\"\\n        \"],[1,[28,[32,0],[\"user-check\"],null]],[1,\"\\n        \"],[10,0],[12],[1,[30,0,[\"userBannerText\"]]],[13],[1,\"\\n        \"],[10,0],[14,0,\"icon-right\"],[12],[1,[28,[32,0],[\"exclamation-triangle\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/swapd-kyc-checker/discourse/connectors/topic-above-footer-buttons/swapd-kyc-banner.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SwapdKycBanner;
});